import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../utils/apiHelpers';
import { Link } from 'react-router-dom';
import Pagination from '../common/Pagination';
import './IssueReport.css';
import StickyHeader from '../common/StickyHeader';
import IssueFilters from '../issues/IssueFilters';
import Loader from '../common/Loader';
import formatter from '../../utils/formatter';
import IssueStats from './IssueStats';

const IssueReport = props => {
  const [loading, setLoading] = useState(false);
  const [originalIssues, setOriginalIssues] = useState([]);
  const [issues, setIssues] = useState([]);
  const [filters, setFilters] = useState({});
  const [count, setCount] = useState(0);
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setIssues(filter(JSON.parse(JSON.stringify(originalIssues))));
    if (!loading && !Object.keys(originalIssues).length) getIssues();
  }, []);

  useEffect(() => {
    getIssues();
  }, [filters]);

  const handleChangeFilters = filters => {
    setFilters(filters);
  };

  const filter = issuesData => {
    if (filters.title)
      issuesData = issuesData.filter(issue =>
        issue.title.toLowerCase().includes(filters.title.toLowerCase()),
      );
    if (filters.issueType)
      issuesData = issuesData.filter(issue =>
        issue.labels.some(label =>
          label.name.toLowerCase().includes(filters.issueType.toLowerCase()),
        ),
      );
    if (filters.environment)
      issuesData = issuesData.filter(issue =>
        issue.labels.some(label =>
          label.name.toLowerCase().includes(filters.environment.toLowerCase()),
        ),
      );

    if (filters.urgent)
      issuesData = issuesData.filter(issue =>
        issue.labels.some(label => label.name.toLowerCase().includes('urgent')),
      );

    if (filters.sprint)
      issuesData = issuesData.filter(
        issue =>
          issue.milestone && issue.milestone.title == filters.sprint?.title,
      );

    return issuesData;
  };

  const getIssues = async () => {
    try {
      setLoading(true);
      const [issues, issuesCount] = await Promise.all([
        await apiHelper.getIssuesReport(props.match.params.project, filters),
        await apiHelper.getIssuesCount(props.match.params.project, filters),
      ]);
      let mappedIssues = [];
      issues.data.map(elem => {
        elem.issues.map(issue => (issue.column = elem.name));
        mappedIssues = mappedIssues.concat(elem.issues);
      });
      mappedIssues = mappedIssues.map(issue => {
        if (issue.workHours) {
          issue.workHours.DEV = issue.workHours.reduce((prevVal, currVal) => {
            if (currVal.role == 'DEV') {
              return prevVal + currVal.total;
            } else {
              return prevVal;
            }
          }, 0);
          issue.workHours.QA = issue.workHours.reduce((prevVal, currVal) => {
            if (currVal.role == 'QA') {
              return prevVal + currVal.total;
            } else {
              return prevVal;
            }
          }, 0);
        } else {
          issue.workHours = 0;
        }
        return issue;
      });
      setOriginalIssues(mappedIssues);
      setIssues(filter(mappedIssues));
      setCount(issuesCount.data.count);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      console.log('Error loading issues');
    }
  };

  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader
        titulo={'Reporte de horas'}
        icon={'fas fa-chart-bar icon-vtasks'}
      />

      <div className="container">
        <IssueFilters
          loadData={handleChangeFilters}
          project={props.match.params.project}
        />

        <div className="table-responsive mt-3">
          {loading ? (
            <Loader />
          ) : (
            <>
              <IssueStats issues={issues} />
              <table className="table  table-hover">
                <thead>
                  <tr>
                    <th className="align-middle" scope="col">
                      ID
                    </th>
                    <th className="align-middle" scope="col">
                      Título
                    </th>
                    <th className="align-middle" scope="col">
                      Horas DEV
                    </th>
                    <th className="align-middle" scope="col">
                      Horas QA
                    </th>
                    <th className="align-middle" scope="col">
                      Total
                    </th>
                    <th className="align-middle" scope="col">
                      Asignado a
                    </th>
                    <th className="align-middle" scope="col">
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {issues.map(issue => {
                    return (
                      <tr key={issue.id}>
                        <td>{issue.id}</td>
                        <td>{issue.title}</td>
                        <td>
                          {issue.workHours &&
                          !formatter
                            .formatWorkHours(issue.workHours.DEV)
                            .includes('NaN')
                            ? formatter.formatWorkHours(issue.workHours.DEV)
                            : 'Sin horas registradas'}
                        </td>
                        <td>
                          {issue.workHours &&
                          !formatter
                            .formatWorkHours(issue.workHours.QA)
                            .includes('NaN')
                            ? formatter.formatWorkHours(issue.workHours.QA)
                            : 'Sin horas registradas'}
                        </td>
                        <td>
                          {issue.workHours &&
                          !formatter
                            .formatWorkHours(
                              issue.workHours.DEV + issue.workHours.QA,
                            )
                            .includes('NaN')
                            ? formatter.formatWorkHours(
                                issue.workHours.DEV + issue.workHours.QA,
                              )
                            : issue.workHours &&
                              !formatter
                                .formatWorkHours(issue.workHours.DEV)
                                .includes('NaN')
                            ? formatter.formatWorkHours(issue.workHours.DEV)
                            : issue.workHours &&
                              !formatter
                                .formatWorkHours(issue.workHours.QA)
                                .includes('NaN')
                            ? formatter.formatWorkHours(issue.workHours.QA)
                            : 'Sin horas registradas'}
                        </td>
                        <td>
                          {issue.assignee
                            ? issue.assignee.login
                            : 'Sin asignar'}
                        </td>
                        <td>
                          <div className="text-center">
                            <button
                              type="button"
                              className="btn buttEdit btn-circle">
                              <Link
                                to={`${process.env.PUBLIC_URL}/project/${props.match.params.project}/issue/${issue.number}`}>
                                <span className="fa fa-edit" />
                              </Link>
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default IssueReport;
