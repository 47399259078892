import React from 'react';

import { styled } from '../../stitches.config';
import './DragAndDrop/Item.css';
import apiHelper from '../../utils/apiHelpers';
import formatter from '../../utils/formatter';

const SprintItem = ({ issue, showCases = true }) => {
  const StyledItem = styled('div', {
    backgroundColor: '#eee',
    borderRadius: 4,
    padding: '4px 8px',
    transition: 'background-color .8s ease-out',
    marginTop: 8,
    width: '250px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#779fe9',
      transition: 'background-color .1s ease-in',
    },
  });

  const getTagClass = tag => {
    switch (tag) {
      case 'customer':
      case 'Customer':
        if (apiHelper.isClient()) {
          return '';
        } else {
          return <label className="tag tag-customer">Cliente</label>;
        }
        break;
      case 'Feature':
        return <label className="tag tag-feature">Funcionalidad</label>;
        break;
      case 'Error':
        return <label className="tag tag-error">Error</label>;
        break;
      case 'Support':
        return <label className="tag tag-support">Soporte</label>;
        break;
      case 'Development':
        return <label className="tag tag-development">Desarrollo</label>;
        break;
      case 'Production':
        return <label className="tag tag-production">Producción</label>;
        break;
      case 'Urgent':
        return <label className="tag tag-urgent">Urgente</label>;
        break;
      case 'Attention':
        return <label className="tag tag-attention">Requiere Atención</label>;
        break;
      case 'Suggestion':
        return <label className="tag tag-suggestion">Sugerencia</label>;
        break;
      case 'Design':
        return <label className="tag tag-design">Require Diseño</label>;
        break;
      case 'ReadOnly':
        return;
      default:
        if (showCases) {
          return <label className="tag tag-default">{tag}</label>;
        } else {
          return;
        }
        break;
    }
  };

  return (
    <StyledItem>
      {
        <div>
          {issue.labels.map(label => {
            return getTagClass(label.name);
          })}

          <li className="list-group-item text-gray-800 clickable">
            <span className="issueTitle">
              <b>#{issue.number}</b> {issue.title}
            </span>
          </li>
          <div className="d-flex flex-column">
            {issue.milestone ? (
              <span className="issueTitle">
                <i className="fas fa-sign mr-1" />
                {issue.milestone.title}
              </span>
            ) : (
              ''
            )}
            {issue.state === 'closed' && !issue.locked ? (
              <span className="issueTitle ml-1">
                <i className="fas fa-calendar-check mr-1" />
                {formatter.formatDateShort(issue.closed_at)}
              </span>
            ) : (
              ''
            )}
            {issue.state === 'closed' && issue.locked ? (
              <span className="issueTitle ml-1">
                <i className="fas fa-check mr-1" />
                Desplegado
              </span>
            ) : (
              ''
            )}
            {issue?.assignees?.length > 0 ? (
              <span className="issueTitle ml-1">
                <i class="fas fa-solid fa-user mr-1"></i>
                {issue.assignees.find(assignee => assignee.role == 'DEV')
                  ? issue.assignees.find(assignee => assignee.role == 'DEV')
                      .login
                  : issue.assignees[0].login}
              </span>
            ) : (
              ''
            )}
          </div>
        </div>
      }
    </StyledItem>
  );
};

export default SprintItem;
