import React from 'react';
import { Link } from 'react-router-dom';
import './BreadCrum.css';

export const BreadCrum = props => {
  return (
    <nav className="d-inline-block fixed" aria-label="breadcrumb">
      <ol className="breadcrumb">
        <i className={props.icon}></i>
        <li className="breadcrumb-item pl-2">
          <Link to={`${process.env.PUBLIC_URL}/project/${props.project}`}>
            {props.back}
          </Link>
        </li>
        <li
          className={`breadcrumb-item active ${
            props.titleClass ? props.titleClass : ' '
          }`}
          aria-current="page">
          {props.actual}
        </li>
      </ol>
    </nav>
  );
};

export const BreadCrumId = props => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <i className={props.icon}></i>
        <li className="breadcrumb-item pl-2">
          <Link to={`${process.env.PUBLIC_URL}/${props.url}`}>
            {props.backBread}
          </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {props.actual}
        </li>
      </ol>
    </nav>
  );
};

export const GenericBreadCrum = ({ list = [] }) => {
  return (
    <nav className="d-inline-block fixed" aria-label="breadcrumb">
      <ol className="breadcrumb">
        {/* <i className={props.icon}></i> */}
        {list.map((item, i) => {
          if (i == list.length - 1) {
            return (
              <li
                className={`breadcrumb-item active title-ellipsis`}
                aria-current="page"
                key={`BREAD_CRUM_4{${i}}`}>
                {item.title}
              </li>
            );
          } else {
            return (
              <li className="breadcrumb-item pl-2" key={`BREAD_CRUM_4{${i}}`}>
                <Link to={`${process.env.PUBLIC_URL}${item.path}`}>
                  {item.title}
                </Link>
              </li>
            );
          }
        })}
      </ol>
    </nav>
  );
};
