const VERIFY_BY_PM = 'VERIFY_BY_PM';

const useCaseUtils = {
  formatUseCaseTitleToLabel: useCaseTitle => {
    let text = useCaseTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    text = text.replaceAll(' ', '_');
    return `UC-${text}`;
  },
  getSolvedUseCaseLabelName: useCaseTitle => {
    let text = useCaseTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    text = text.replaceAll(' ', '_');
    return `VERIFY-UC-${text}`;
  },
  isUseCaseSolvedForIssue: (issue, useCaseTitle) => {
    const labels = issue.labels;
    const solvedGeneral = labels.find(label => label.name == VERIFY_BY_PM);
    const solvedUseCase = labels.find(
      label =>
        label.name == useCaseUtils.getSolvedUseCaseLabelName(useCaseTitle),
    );
    if (solvedGeneral || solvedUseCase) {
      return true;
    } else {
      return false;
    }
  },
};

export default useCaseUtils;
