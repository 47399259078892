import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { styled } from '../../../stitches.config';
import './ItemPending.css';
import apiHelper from '../../../utils/apiHelpers';
import formatter from '../../../utils/formatter';

const UseCaseItem = props => {
  const StyledItem = styled('div', {
    backgroundColor: '#eee',
    borderRadius: 4,
    padding: '4px 8px',
    transition: 'background-color .8s ease-out',
    marginTop: 8,

    ':hover': {
      backgroundColor: '#fff',
      transition: 'background-color .1s ease-in',
    },
  });

  const getTagClass = tag => {
    switch (tag) {
      case 'customer':
      case 'Customer':
        if (apiHelper.isClient()) {
          return '';
        } else {
          return (
            <label key={tag} className="tag tag-customer">
              Cliente
            </label>
          );
        }
        break;
      case 'Feature':
        return (
          <label key={tag} className="tag tag-feature">
            Funcionalidad
          </label>
        );
        break;
      case 'Design':
        return (
          <label key={tag} className="tag tag-design">
            Requiere Diseño
          </label>
        );
        break;
      case 'Error':
        return (
          <label key={tag} className="tag tag-error">
            Error
          </label>
        );
        break;
      case 'Support':
        return (
          <label key={tag} className="tag tag-support">
            Soporte
          </label>
        );
        break;
      case 'Development':
        return (
          <label key={tag} className="tag tag-development">
            Desarrollo
          </label>
        );
        break;
      case 'Production':
        return (
          <label key={tag} className="tag tag-production">
            Producción
          </label>
        );
        break;
      case 'Urgent':
        return (
          <label key={tag} className="tag tag-urgent">
            Urgente
          </label>
        );
        break;
      case 'Attention':
        return (
          <label key={tag} className="tag tag-attention">
            Requiere Atención
          </label>
        );
        break;
      case 'Suggestion':
        return (
          <label key={tag} className="tag tag-suggestion">
            Sugerencia
          </label>
        );
        break;
      default:
        return;
        break;
    }
  };

  return (
    <Draggable
      draggableId={props.useCase.id.toString()}
      index={props.index}
      isDragDisabled={false}>
      {provided => (
        <StyledItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          {
            <div>
              {props.useCase.title}
              <Link
                to={`${process.env.PUBLIC_URL}/project/${props.project}/useCases/${props.useCase.id}`}>
                <li className="list-group-item text-gray-800 clickable">
                  <span className="issueTitle">
                    {props.useCase.issuesNumbers.map((number, i) => {
                      return (
                        <b key={`${number}-${i}-keyforitemusecase`}>
                          #{number}
                        </b>
                      );
                    })}
                  </span>
                </li>
              </Link>
            </div>
          }
        </StyledItem>
      )}
    </Draggable>
  );
};

export default UseCaseItem;
