import React, { useEffect, useState } from 'react';
import StickyHeader from '../common/StickyHeader';
import apiHelpers from '../../utils/apiHelpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { GenericBreadCrum } from '../common/BreadCrum';

const INIT_FILTERS = {
  title: '',
};

const UseCaseList = ({}) => {
  const { project: projectName } = useParams();
  const [useCases, setUseCases] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUseCases(INIT_FILTERS);
  }, []);

  const getUseCases = async filters => {
    setLoading(true);
    const response = await apiHelpers.getUseCases(projectName, filters);
    setUseCases(response.data.useCases);
    setLoading(false);
  };
  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader
        titulo={'Casos de uso'}
        icon={'fas fa-wrench icon-vtasks'}
      />
      <GenericBreadCrum
        list={[
          { title: projectName, path: `/project/${projectName}` },
          { title: 'Casos de uso', path: `/project/${projectName}/useCases` },
        ]}></GenericBreadCrum>
      <div className="container mt-4">
        <div className="col-lg-3 ">
          <Link
            to={`${process.env.PUBLIC_URL}/project/${projectName}/useCases/new`}>
            <button
              type="button"
              className="btn butt btn-success pull-right w-100 twelvethStep mb-4">
              Agregar caso de uso <i className="fas fa-plus-square" />
            </button>
          </Link>
        </div>

        <table className="table table-hover ">
          <thead>
            <tr>
              <th className="align-middle" scope="col">
                Título
              </th>
              <th className="align-middle" scope="col">
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {useCases.map((useCase, i) => {
              return (
                <tr key={useCase.id}>
                  <td>
                    <span className="text-grey">{useCase.title}</span>
                  </td>
                  <td>
                    <div className="text-center">
                      <Link
                        to={`${process.env.PUBLIC_URL}/project/${projectName}/useCases/${useCase.id}`}>
                        <button
                          type="button"
                          className="btn buttEdit btn-circle">
                          <span className="fa fa-eye text-white" />
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default UseCaseList;
