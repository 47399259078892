import React from 'react';
import { useEffect, useState } from 'react';
import apiHelper from '../../../utils/apiHelpers';
import './IssuesPending.css';
import StickyHeader from '../../common/StickyHeader';
import Loader from '../../common/Loader';
import ProgressLoader from '../../common/ProgressLoader';
import ColumnPending from './ColumnPending';
import { DragDropContext } from 'react-beautiful-dnd';
import { styled } from '../../../stitches.config';
import 'intro.js/introjs.css';

const IssuesPending = props => {
  const [loading, setLoading] = useState(true);
  const [allProjects, setAllProjects] = useState(null);
  const [projectLoading, setProjectLoading] = useState({
    projectName: null,
    loadingStep: null,
  });
  const [columns, setColumns] = useState({});
  const [originalColumns, setOriginalColumns] = useState({});

  useEffect(() => {
    getIssues();
    setColumns(JSON.parse(JSON.stringify(originalColumns)));
    if (!loading && !Object.keys(originalColumns).length) getIssues();
  }, []);
  useEffect(() => {
    console.log(columns);
  }, [columns]);

  const getIssues = async () => {
    setLoading(true);
    try {
      let { data: repositories } = await apiHelper.getProjects();
      let {
        data: { projects: userAssignedProjectsInDB },
      } = await apiHelper.getCurrentUser();
      repositories = repositories.filter(repo =>
        userAssignedProjectsInDB.includes(String(repo.id)),
      );
      const projectColumns = [];
      setAllProjects(repositories);
      let loadingStep = 1;
      for (const project of repositories) {
        setProjectLoading({
          projectName: project.name,
          loadingStep,
        });
        const { data: projectPendings } = await apiHelper.getPendingIssues(
          project.name,
        );
        if (projectPendings?.issues?.length > 0) {
          projectColumns.push(projectPendings);
        }
        if (apiHelper.isPM()) {
          projectColumns.push(projectPendings);
        }
        loadingStep++;
      }
      setOriginalColumns(projectColumns);
      setColumns(projectColumns);
    } catch (e) {
      console.log(e);
      console.log('Error loading issues');
    }
    setLoading(false);
  };

  const StyledColumns = styled('div', {
    display: 'flex',
    height: '80vh',
    minWidth: '200px',
    gap: '8px',
  });

  return (
    <div className="d-flex flex-column h-100 general">
      <StickyHeader
        titulo={'Tareas pendientes'}
        icon={'fas fa-tasks icon-vtasks'}
      />

      <div className="container mw-100">
        {loading ? (
          <div className="p-4">
            <Loader />

            <ProgressLoader
              currentValue={projectLoading.loadingStep}
              fullProcess={allProjects?.length}
            />

            {projectLoading?.projectName ? (
              <h3 className="text-muted text-center">
                <p class="font-italic">
                  Buscando tareas pendientes en{' '}
                  <p class="font-weight-bold">{projectLoading?.projectName}</p>
                </p>
              </h3>
            ) : null}
          </div>
        ) : (
          <DragDropContext>
            <StyledColumns>
              {Object.values(columns)?.map((column, index) => {
                return (
                  <ColumnPending
                    key={column.id}
                    project={column.name}
                    column={column}
                  />
                );
              })}
            </StyledColumns>
          </DragDropContext>
        )}
      </div>
    </div>
  );
};

export default IssuesPending;
