import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import StickyHeader from '../common/StickyHeader';
import Tooltip from '../common/Tooltip';
import { BreadCrum, GenericBreadCrum } from '../common/BreadCrum';
import apiHelpers from '../../utils/apiHelpers';
import { useAlert } from '../../context/AlertContext';
import ReactPlayer from 'react-player';
import Loader from '../common/Loader';
import SprintItem from '../issues/SprintItem';
import { Link } from 'react-router-dom';
import formatter from '../../utils/formatter';
import useCaseUtils from '../../utils/useCasesUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const INIT_STATE = {
  title: '',
  description: '',
};

const UseCaseDetail = ({}) => {
  const { project: projectName, id: useCaseId } = useParams();
  const history = useHistory();
  const [solved, setSolved] = useState(false);

  const [useCase, setUseCase] = useState(INIT_STATE);
  const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filesToDisplay, setFilesToDisplay] = useState([]);
  const refHiddenFileInput = useRef(null);

  const [copied, setCopied] = useState(false);

  const alertToast = useAlert();
  useEffect(() => {
    if (useCaseId == 'new') {
      setLoading(false);
    } else {
      getUseCase();
    }
  }, []);
  const isUseCaseSolved = (issues, title) => {
    for (let i = 0; i < issues.length; i++) {
      if (!useCaseUtils.isUseCaseSolvedForIssue(issues[i], title)) {
        return false;
      }
    }
    return true;
  };
  const getUseCase = async () => {
    setLoading(true);
    const response = await apiHelpers.getUseCaseById(projectName, useCaseId);
    extractMediaUrls(response.data.useCase.description);
    setUseCase(response.data.useCase);
    setIssues(response.data.issues);
    if (isUseCaseSolved(response.data.issues, response.data.useCase.title)) {
      setSolved(true);
    }
    setLoading(false);
  };
  const rejectUseCase = async () => {
    try {
      setLoading(true);
      const response = await apiHelpers.rejectUseCase(projectName, useCaseId);
      history.push(`/project/${projectName}/useCases`);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setAsSolve = async () => {
    try {
      setLoading(true);
      const response = await apiHelpers.setUseCaseAsSolve(
        projectName,
        useCaseId,
      );
      history.push(`/project/${projectName}/useCases`);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleFileChangeForBody = async event => {
    let file = event.target.files[0];
    if (file) {
      try {
        setLoading(true);
        const response = await apiHelpers.postAttachmentForIssue(file);
        const fileUrl = response.data.fileUrl;
        // const fileUrl =
        // 'https://gratisography.com/wp-content/uploads/2024/10/gratisography-cool-cat-800x525.jpg';
        let finalPath;
        switch (file.type) {
          case 'image/png':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/jpeg':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/jpg':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'image/webp':
            finalPath = `![Image](${fileUrl})`;
            break;
          case 'video/mp4':
            finalPath = fileUrl;
            // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
            break;
          // case 'video/avi':
          //   finalPath = fileUrl;
          //   // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
          //   break;
          case 'video/x-matroska':
            finalPath = fileUrl;
            // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
            break;
          // case 'video/quicktime':
          //   finalPath = fileUrl;
          //   // finalPath=`https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/5e6ce1ef-e4a0-47ab-98eb-68ec22243085..mp4`
          //   break;
          default:
            // finalPath = `[File](https://dev.vtasks.venturing.com.ar/vTasks/api/attachments/467ec44a-a1f1-4fbc-b389-a99428ea487e..csv)`;
            finalPath = `[File](${fileUrl})`;
            break;
        }
        setUseCase({
          ...useCase,
          description: `${finalPath}
${useCase.description}`,
        });

        extractMediaUrls(`${finalPath}
${useCase.description}`);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('ERRORR');
    }
    setLoading(false);
  };

  function extractMediaUrls(text) {
    const videoRegex = /https?:\/\/[^\s]+\.(mp4|mkv)/g;
    const imageRegex = /!\[Image\]\((https?:\/\/[^\s]+)\)/g;
    const fileRegex = /\[File\]\((https?:\/\/[^\s)]+)\)/g;
    const mediaUrls = [];
    let match;

    // Buscar videos
    while ((match = videoRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'video', url: match[0] });
    }

    // Buscar imágenes
    while ((match = imageRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'image', url: match[1] });
    }
    //Buscar archivos
    while ((match = fileRegex.exec(text)) !== null) {
      mediaUrls.push({ type: 'file', url: match[1] });
    }
    setFilesToDisplay(mediaUrls);
    return mediaUrls;
  }

  const handlePickFileForBody = e => {
    refHiddenFileInput.current.click();
  };

  const handleChange = event => {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    const name = target.name;

    setUseCase({
      ...useCase,
      [name]: value,
    });
    extractMediaUrls(event.target.value);
  };

  const extractFileNameFromUrl = text => {
    const items = text.split('/');
    if (items.length > 1) {
      const fileName = items[items.length - 1];
      return fileName;
    }
    return text;
  };

  const onPasteBody = async e => {
    const dT = e.clipboardData || window.clipboardData;
    const file = dT.files[0];
    if (dT.files.length == 0) {
      return;
    }
    handleFileChangeForBody({ target: { files: [file] } });
  };

  const handleSave = async e => {
    setLoading(true);
    e.preventDefault();
    if (useCaseId == 'new') {
      const response = await apiHelpers.postUseCase(projectName, useCase);
      // window.location.replace(`/project/${projectName}/useCases`);
    }
    history.push(`/project/${projectName}/useCases`);
  };
  return (
    <div className="d-flex flex-column h-100 general">
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
          <StickyHeader
            titulo={
              useCaseId == 'new'
                ? 'Crear caso de uso'
                : useCase.title
                ? useCase.title
                : 'Detalle de caso de uso'
            }
            icon={'fas fa-wrench icon-vtasks'}
          />

          <GenericBreadCrum
            list={[
              { title: projectName, path: `/project/${projectName}` },
              {
                title: 'Casos de uso',
                path: `/project/${projectName}/useCases`,
              },
              {
                title: useCase.title ? useCase.title : 'Detalle de caso de uso',
                path: `/project/${projectName}/useCases/1`,
              },
            ]}></GenericBreadCrum>
          <div className="container">
            <div className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-12 secondStep">
                <div className="form-group">
                  <h4
                    className="inline-block text-white"
                    htmlFor="title"
                    data-tip
                    data-for={'Tooltip-title'}>
                    Título
                  </h4>
                  <Tooltip
                    id={'Tooltip-title'}
                    tooltipText={
                      'Síntesis del caso de uso. Ej: "Inicio de sesión"'
                    }
                  />

                  <input
                    type="text"
                    name="title"
                    maxLength="140"
                    className="form-control"
                    placeholder="No definido"
                    value={useCase.title}
                    onChange={handleChange}
                    //   disabled={isDisabled}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="d-flex flex-wrap justify-content-between mb-4 w-100 mx-3">
                {issues.map((issue, i) => {
                  return (
                    <Link
                      to={`${process.env.PUBLIC_URL}/project/${projectName}/issue/${issue.number}`}>
                      <div key={`${issue.id}-i${i}.dadsad`}>
                        <SprintItem
                          issue={issue}
                          showCases={false}></SprintItem>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              <div className="col-md-12 col-lg-12 secondStep">
                <div className="form-group">
                  <h4
                    className="inline-block text-white"
                    htmlFor="title"
                    data-tip
                    data-for={'Tooltip-description'}>
                    Descripción
                  </h4>
                  <Tooltip
                    id={'Tooltip-description'}
                    tooltipText={
                      'Explique el caso de uso con el mayor grado de detalle posible, incluyendo adjuntos, ejemplos y datos de prueba.'
                    }
                  />

                  <div className="issue-body-enabled">
                    {filesToDisplay.length > 0 && (
                      <div className="d-flex justify-content-between flex-wrap pb-2">
                        {filesToDisplay.map((content, i) => {
                          const handleCopy = () => {
                            alertToast.info('Link copiado');
                            navigator.clipboard
                              .writeText(content.url)
                              .then(() => {
                                setCopied(true);
                                setTimeout(() => setCopied(false), 2000);
                              });
                          };
                          if (content.type == 'image') {
                            return (
                              <div className="position-relative">
                                <img
                                  src={content.url}
                                  width={'95%'}
                                  style={{ borderRadius: '5px' }}
                                  className="mt-3 mb-1 mr-3 ml-3"
                                  key={`--${i}--${content.type}--${i}`}></img>
                                <button
                                  className="copy-button-image text-center"
                                  onClick={handleCopy}
                                  data-tip
                                  data-for={'copylinkbody'}>
                                  <i class="far fa-clipboard"></i>
                                  <Tooltip
                                    id="copylinkbody"
                                    tooltipText="Copiar Link"></Tooltip>
                                </button>
                              </div>
                            );
                          }
                          if (content.type == 'video') {
                            return (
                              <ReactPlayer
                                url={content.url}
                                controls={true}
                                key={`--${i}--${content.type}--${i}`}
                                width={'100%'}></ReactPlayer>
                            );
                          }
                          if (content.type == 'file') {
                            return (
                              <a
                                target="_blank"
                                href={content.url}
                                className="mt-3 mb-1 mr-3 ml-3">
                                <button className="btn butt btn-info mr-3">
                                  <i className="fas fa-external-link-alt mr-1" />
                                  {extractFileNameFromUrl(content.url)}
                                </button>
                              </a>
                            );
                          }
                        })}
                      </div>
                    )}
                    <textarea
                      type="text"
                      name="description"
                      className="textarea-enabled"
                      rows={5}
                      placeholder="No definido"
                      value={useCase.description}
                      onChange={handleChange}
                      //   disabled={isDisabled}
                      onPaste={onPasteBody}
                      required
                    />
                  </div>
                  <button
                    className="btn butt btn-info mr-3  mt-2"
                    onClick={handlePickFileForBody}
                    data-tip
                    data-for={'Tooltipimportissue'}>
                    <Tooltip
                      id="Tooltipimportissue"
                      tooltipText="Soporta todo tipo de archivos. Reconoce: Video: .mp4 | .mkv - Imagenes: .png | .jpeg | .jpg | .webp"></Tooltip>
                    Adjuntar archivo
                    <i className="fas fa-paperclip ml-2" />
                  </button>
                  <input
                    id="file"
                    type="file"
                    style={{ display: 'none' }}
                    ref={refHiddenFileInput}
                    onChange={handleFileChangeForBody}
                    className="form-control-file"
                  />
                </div>
              </div>
            </div>

            <div className="row mb-1 mt-5 justify-content-center">
              {!solved && (
                <div className="col-md-12 col-lg-4">
                  <button
                    className="btn butt w-100 sixthStep"
                    // onClick={submitIssue}
                    onClick={rejectUseCase}>
                    <i className="fas fa-times mr-2" />
                    Rechazar
                  </button>
                </div>
              )}
              <div className="col-md-12 col-lg-4">
                <button
                  className="btn butt w-100 sixthStep"
                  // onClick={submitIssue}
                  onClick={handleSave}>
                  <i className="fas fa-save mr-2" />
                  Guardar
                </button>
              </div>
              {!solved && (
                <div className="col-md-12 col-lg-4">
                  <button
                    className="btn butt w-100 sixthStep"
                    // onClick={submitIssue}
                    onClick={setAsSolve}>
                    <i className="fas fa-check mr-2" />
                    Marcar como verificado
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UseCaseDetail;
