import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import apiHelpers from '../../../utils/apiHelpers';

const SelectUseCase = ({
  value = [],
  name,
  projectName,
  required,
  placeholder,
  disabled,
  onChange,
  exclude = [],
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    onLoad('', () => {});
  }, []);

  const onLoad = (input, callback) => {
    apiHelper.getUseCases(projectName, { title: input }).then(response => {
      let result = response.data.useCases.map(useCase => {
        return {
          value: useCase.id,
          label: useCase.title,
        };
      });
      result = result.filter(b => !exclude.includes(b.value));
      setOptions(result);
      callback(result);
    });
  };
  const onChangeWrapper = (selected, triggeredAction) => {
    if (selected == null) {
      onChange([]);
    } else {
      onChange(selected);
    }
  };

  return (
    <AsyncSelect
      onChange={onChangeWrapper}
      loadOptions={onLoad}
      value={options.filter(o => value.includes(o.value))}
      name={name}
      isMulti
      defaultOptions
      required={required}
      placeholder={placeholder ? placeholder : 'Nombre del caso de uso'}
      isDisabled={disabled ? disabled : false}
      noOptionsMessage={() =>
        'No se encontraron casos de uso con el nombre ingresado'
      }
      styles={{
        option: provided => ({
          ...provided,
          color: 'black',
        }),
        control: provided => ({
          ...provided,
          color: 'black',
        }),
        singleValue: provided => ({
          ...provided,
          color: 'black',
        }),
      }}
    />
  );
};

export default SelectUseCase;
