import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { styled } from '../../../stitches.config';
import './ItemDelayed.css';
import apiHelper from '../../../utils/apiHelpers';
import formatter from '../../../utils/formatter';

const Item = props => {
  const StyledItem = styled('div', {
    backgroundColor: '#eee',
    borderRadius: 4,
    padding: '4px 8px',
    transition: 'background-color .8s ease-out',
    marginTop: 8,

    ':hover': {
      backgroundColor: '#fff',
      transition: 'background-color .1s ease-in',
    },
  });

  const getTagClass = tag => {
    switch (tag) {
      case 'customer':
      case 'Customer':
        if (apiHelper.isClient()) {
          return '';
        } else {
          return (
            <label key={tag} className="tag tag-customer">
              Cliente
            </label>
          );
        }
        break;
      case 'Feature':
        return (
          <label key={tag} className="tag tag-feature">
            Funcionalidad
          </label>
        );
        break;
      case 'Design':
        return (
          <label key={tag} className="tag tag-design">
            Requiere Diseño
          </label>
        );
        break;
      case 'Error':
        return (
          <label key={tag} className="tag tag-error">
            Error
          </label>
        );
        break;
      case 'Support':
        return (
          <label key={tag} className="tag tag-support">
            Soporte
          </label>
        );
        break;
      case 'Development':
        return (
          <label key={tag} className="tag tag-development">
            Desarrollo
          </label>
        );
        break;
      case 'Production':
        return (
          <label key={tag} className="tag tag-production">
            Producción
          </label>
        );
        break;
      case 'Urgent':
        return (
          <label key={tag} className="tag tag-urgent">
            Urgente
          </label>
        );
        break;
      case 'Attention':
        return (
          <label key={tag} className="tag tag-attention">
            Requiere Atención
          </label>
        );
        break;
      case 'Suggestion':
        return (
          <label key={tag} className="tag tag-suggestion">
            Sugerencia
          </label>
        );
        break;
      default:
        return (
          <label key={tag} className="tag tag-default">
            {tag}
          </label>
        );
        break;
    }
  };

  return (
    <Draggable
      draggableId={props.issue.id.toString()}
      index={props.index}
      isDragDisabled={true}>
      {provided => (
        <StyledItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          {
            <div>
              {props.issue.labels.map(label => {
                return getTagClass(label.name);
              })}
              <Link
                to={`${process.env.PUBLIC_URL}/project/${props.project}/issue/${props.issue.number}`}>
                <li className="list-group-item text-gray-800 clickable">
                  <span className="issueTitle">
                    <b>#{props.issue.number}</b> {props.issue.title}
                  </span>
                </li>
              </Link>
              <div className="d-flex flex-column h-auto m-2">
                {props.issue.updated_at || props.issue.created_at ? (
                  <span className="issueTitle m-1">
                    <i class="fas fa-clock mr-1"></i>
                    {new Date(
                      props.issue.updated_at || props.issue.created_at,
                    ).toLocaleDateString()}
                  </span>
                ) : (
                  ''
                )}
                {props.issue.milestone ? (
                  <span className="issueTitle">
                    <i className="fas fa-sign mr-1" />
                    {props.issue.milestone.title}
                  </span>
                ) : (
                  ''
                )}
                {props.issue.state === 'closed' && !props.issue.locked ? (
                  <span className="issueTitle m-1">
                    <i className="fas fa-calendar-check mr-1" />
                    {formatter.formatDateShort(props.issue.closed_at)}
                  </span>
                ) : (
                  ''
                )}
                {props.issue.state === 'closed' && props.issue.locked ? (
                  <span className="issueTitle m-1">
                    <i className="fas fa-check mr-1" />
                    Desplegado
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          }
        </StyledItem>
      )}
    </Draggable>
  );
};

export default Item;
