import React from 'react';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import apiHelpers from '../../../utils/apiHelpers';

const SelectIssueMilestone = props => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (!props.options?.length && props.project) {
      apiHelpers.getProjectMilestones(props.project).then(response => {
        let values = response?.data?.milestones.map(milestone => {
          return {
            value: {
              number: milestone.number,
              id: milestone.id,
              title: milestone.title,
            },
            label:
              milestone.title +
              ' ' +
              (milestone.closed_at ? milestone.closed_at : ''),
          };
        });
        setOptions(values);
      });
    } else {
      if (props.options.length) {
        setOptions(props.options);
      }
    }
  }, [value]);

  useEffect(() => {
    let value = props.value;
    if (value != undefined) {
      value = options.find(option => option.value.id === value.id);
    }

    setValue(value);
  }, [options, props.value]);

  const onChangeWrapper = value => {
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-nameless';
    event.target.value = value?.value ? value.value : null;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  return (
    <Select
      className={props.selectClassName}
      name={props.name}
      onChange={onChangeWrapper.bind(this)}
      value={value || ''}
      required={props.required}
      options={options}
      defaultOptions={options}
      clearable={false}
      placeholder={props.placeholder}
      menuContainerStyle={{ zIndex: 999 }}
      isDisabled={props.disabled}
      isClearable
    />
  );
};

export default SelectIssueMilestone;
