import React from 'react';
import { useEffect, useState } from 'react';
import './IssueFilters.css';
import SelectIssueEnvironment from '../common/selects/SelectIssueEnvironment';
import SelectIssueUrgent from '../common/selects/SelectIssueUrgent';
import SelectIssueType from '../common/selects/SelectIssueType';
import SelectIssueMilestone from '../common/selects/SelectIssueMilestone';
import SelectIssueAttention from '../common/selects/SelectIssueAttention';
import SelectUseCaseFilter from '../common/selects/SelectUseCaseFilter';

const IssueFilters = props => {
  const [state, setState] = useState({
    state: null,
  });

  useEffect(() => {
    props.loadData(getFiltersObject());
  }, [state]);

  const getFiltersObject = () => {
    return {
      title: state.title,
      issueType: state.issueType,
      environment: state.environment,
      urgent: state.urgent,
      attention: state.attention,
      sprint: state.sprint,
      useCase: state.useCase,
    };
  };

  const onFiltersChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setState({ [name]: value });
  };

  const onFiltersRefresh = () => {
    setState({
      state: null,
    });
  };

  return (
    <>
      <div className="row transactions-filters">
        <div className="col-lg-2 col-sm-6">
          <input
            type="text"
            className="form-control"
            placeholder="Título de la tarea"
            value={state.title ? state.title : ''}
            name={'title'}
            onChange={onFiltersChange.bind(this)}
          />
        </div>
        <div className="col-lg-2 col-sm-6">
          <SelectIssueType
            value={state.issueType}
            name={'issueType'}
            placeholder="Tipo de tarea"
            onChange={onFiltersChange.bind(this)}
            project={props.project}
          />
        </div>
        <div className="col-lg-2 col-sm-6">
          <SelectIssueEnvironment
            value={state.environment}
            placeholder="Ambiente"
            name={'environment'}
            onChange={onFiltersChange.bind(this)}
            project={props.project}
          />
        </div>
        <div className="col-lg-2 col-sm-6">
          <SelectIssueUrgent
            value={state.urgent}
            placeholder="¿Es urgente?"
            name={'urgent'}
            onChange={onFiltersChange.bind(this)}
            project={props.project}
          />
        </div>
        <div className="col-lg-2 col-sm-6">
          <SelectIssueAttention
            value={state.attention}
            placeholder="¿Requiere atención?"
            name={'attention'}
            onChange={onFiltersChange.bind(this)}
            project={props.project}
          />
        </div>
        <div className="col-lg-2 col-sm-6">
          <SelectIssueMilestone
            value={state.sprint}
            placeholder="Hito"
            name={'sprint'}
            onChange={onFiltersChange.bind(this)}
            options={props.sprintOptions}
            project={props.project}
          />
        </div>
        <div className="col-lg-2 col-sm-6">
          <SelectUseCaseFilter
            value={state.useCase}
            placeholder="Caso de uso"
            name={'useCase'}
            onChange={onFiltersChange.bind(this)}
            projectName={props.project}
          />
        </div>
      </div>
      <div className="row transactions-filters">
        {props.additionalOptions}
        <div className="col-lg-2 col-sm-6">
          <div className="text-right">
            <button
              type="button"
              className="btn butt btn-warning w-100"
              onClick={onFiltersRefresh.bind(this)}
              style={{ marginLeft: '2px' }}>
              Reiniciar filtros <i className="fas fa-sync"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueFilters;
