import React from 'react';
import apiHelper from '../../../utils/apiHelpers';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';

const SelectProjectName = props => {
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState('');
  const [value, setValue] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    apiHelper.getCurrentUser().then(response => {
      setCurrentUser(response.data);
    });
  }, []);
  useEffect(() => {
    let { value } = props;
    let userValue;
    if (value) {
      userValue = options.find(option => option === value);
      if (!userValue && value) loadDefaultOptions(value);
    } else {
      setValue(null);
    }
  }, [props.value]);

  const handleChange = selectedValue => {
    setValue(selectedValue);
    let event = { target: {} };
    event.target.name = props.name ? props.name : 'select-options-nameless';
    event.target.value = selectedValue ? selectedValue.value : null;
    event.target.type = 'react-select';
    props.onChange(event);
  };

  const loadOptions = (input, callback) => {
    apiHelper
      .getProjects()
      .then(response => {
        let entitiesForSelect = response.data
          .filter(project =>
            input.length
              ? project.name.toLowerCase().includes(input.toLowerCase())
              : true,
          )
          .map(entity => {
            return {
              value: entity.id,
              label: entity.name,
            };
          });
        entitiesForSelect = entitiesForSelect.filter(repo =>
          currentUser?.projects?.includes(String(repo.value)),
        );
        setOptions(entitiesForSelect);
        callback(entitiesForSelect);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const loadDefaultOptions = value => {
    apiHelper
      .getProjects()
      .then(response => {
        let entitiesForSelect = response.data
          .filter(project =>
            input.length
              ? project.name.toLowerCase().includes(input.toLowerCase())
              : true,
          )
          .map(entity => {
            return {
              value: entity.id,
              label: entity.name,
            };
          });

        entitiesForSelect = entitiesForSelect.filter(repo =>
          currentUser?.projects?.includes(String(repo.value)),
        );
        setOptions(entitiesForSelect);
        const selectedValue = entitiesForSelect.find(
          elem => elem.value == value,
        );
        setValue({ value: selectedValue.value, label: selectedValue.label });
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <AsyncSelect
      onChange={handleChange}
      loadOptions={loadOptions}
      value={value}
      name={props.name}
      required={props.required}
      placeholder={
        props.placeholder ? props.placeholder : 'Nombre del proyecto'
      }
      isDisabled={props.disabled ? props.disabled : false}
      noOptionsMessage={() =>
        'No se encontraron proyectos con el nombre ingresado'
      }
      // styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPlacement="bottom"
    />
  );
};

export default SelectProjectName;
